exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-areas-info-page-js": () => import("./../../../src/pages/areas-info-page.js" /* webpackChunkName: "component---src-pages-areas-info-page-js" */),
  "component---src-pages-cities-info-page-js": () => import("./../../../src/pages/cities-info-page.js" /* webpackChunkName: "component---src-pages-cities-info-page-js" */),
  "component---src-pages-counties-info-page-js": () => import("./../../../src/pages/counties-info-page.js" /* webpackChunkName: "component---src-pages-counties-info-page-js" */),
  "component---src-pages-districts-info-page-js": () => import("./../../../src/pages/districts-info-page.js" /* webpackChunkName: "component---src-pages-districts-info-page-js" */),
  "component---src-pages-municipalities-info-page-js": () => import("./../../../src/pages/municipalities-info-page.js" /* webpackChunkName: "component---src-pages-municipalities-info-page-js" */),
  "component---src-pages-regions-info-page-js": () => import("./../../../src/pages/regions-info-page.js" /* webpackChunkName: "component---src-pages-regions-info-page-js" */),
  "component---src-pages-villages-info-page-js": () => import("./../../../src/pages/villages-info-page.js" /* webpackChunkName: "component---src-pages-villages-info-page-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-municipality-js": () => import("./../../../src/templates/municipality.js" /* webpackChunkName: "component---src-templates-municipality-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-list-municipalities-js": () => import("./../../../src/templates/page-list-municipalities.js" /* webpackChunkName: "component---src-templates-page-list-municipalities-js" */),
  "component---src-templates-page-list-regions-js": () => import("./../../../src/templates/page-list-regions.js" /* webpackChunkName: "component---src-templates-page-list-regions-js" */),
  "component---src-templates-page-list-structures-js": () => import("./../../../src/templates/page-list-structures.js" /* webpackChunkName: "component---src-templates-page-list-structures-js" */),
  "component---src-templates-region-js": () => import("./../../../src/templates/region.js" /* webpackChunkName: "component---src-templates-region-js" */),
  "component---src-templates-structure-js": () => import("./../../../src/templates/structure.js" /* webpackChunkName: "component---src-templates-structure-js" */)
}

